a {
    color: #c60000;
}

a:hover {
    text-decoration: none;
    color: #6e0000;
}

button:focus {
    outline: none;
}

.btn-rounded {
    padding: 3px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 4px 2px;
    border-radius: 8px;
    outline: none;
}

.btn-rounded:focus {
    outline: none;
}

.callout {
    background: url(../images/bgCallout.gif) no-repeat;
    margin: -20px 0 0 0;
    padding: 3px 0 0 12px;
    height: 40px;
    width: auto;
    font: normal 18px Arial;
    color: #fff;
}

.custom-button {
    font-size: 1em;
    padding: 10px;
    border: 2px solid #06D85F;
    border-radius: 20px/50px;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.ck-editor__editable,
textarea {
    min-height: 150px;
}

.datatable {
    width: 100% !important;
}

.dataTables_length,
.dataTables_filter,
.dt-buttons {
    margin-bottom: 0.333em;
    margin-top: .2rem;
}

.dataTables_filter {
    margin-right: .2rem;
}

.dt-buttons .btn {
    margin-left: 0.333em;
    border-radius: 0;
}

.table.datatable {
    box-sizing: border-box;
    border-collapse: collapse;
}

table.dataTable thead th {
    border-bottom: 2px solid #c8ced3;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
    border-bottom: 1px solid #c8ced3;
}

.select2 {
    max-width: 100%;
    width: 100% !important;
}

.select2-selection__rendered {
    padding-bottom: 5px !important;
}

.has-error .invalid-feedback {
    display: block !important;
}

.btn-info,
.badge-info {
    color: white;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background-image: none;
}

.sidebar .nav-item {
    cursor: pointer;
}

.btn-default {
    color: #23282c;
    background-color: #f0f3f5;
    border-color: #f0f3f5;
}

.btn-default.focus,
.btn-default:focus {
    box-shadow: 0 0 0 .2rem rgba(209, 213, 215, .5);
}

.btn-default:hover {
    color: #23282c;
    background-color: #d9e1e6;
    border-color: #d1dbe1;
}

.searchable-title {
    font-weight: bold;
}

.searchable-fields {
    padding-left: 5px;
}

.searchable-link {
    padding: 0 5px 0 5px;
}

.searchable-link:hover {
    cursor: pointer;
    background: #eaeaea;
}

.select2-results__option {
    padding-left: 0px;
    padding-right: 0px;
}

#loader {
    transition: all .3s ease-in-out;
    opacity: 1;
    visibility: visible;
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #fff;
    z-index: 90000
}

#loader.fadeOut {
    opacity: 0;
    visibility: hidden
}

.spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0)
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0
    }
}

.dataTables_wrapper .dataTables_length select {
    padding: .375rem 1.75rem .375rem .75rem !important;
}

.dataTables_length,
.dt-buttons {
    float: left
}

div.dt-button-collection {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 3px;
    padding: 8px 8px 4px 8px;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: white;
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    z-index: 2002;
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    -ms-column-gap: 8px;
    -o-column-gap: 8px;
    column-gap: 8px;
    .dt-button {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: #d9e1e650;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        line-height: 1.6;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .dt-button.active {
        background-color: #3490dc;
        color: #fff;
    }
}

.dt-buttons {
    .btn,
    .dt-button,
    .buttons-columnVisibility {
        display: inline-block;
        font-weight: 400;
        color: #212529;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;
        line-height: 1.6;
        border-radius: 0.25rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .btn:hover,
    .buttons-columnVisibility:hover {
        background-color: #d9e1e6;
        border-color: #d1dbe1;
        border-radius: 0.25rem;
    }
    .btn-group>.btn {
        margin-left: 0px !important;
    }
    .btn-group>.btn:hover {
        margin-left: 0px;
        background-color: #d9e1e6;
        border-color: #d1dbe1;
    }
    .btn-danger {
        color: #fff
    }
}

.member-message,
.chat-support-message {
    img.img_preview {
        width: 250px;
        max-width: 250px;
    }
}