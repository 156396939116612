@media  (min-device-width: 100px) and (max-device-width: 768px)
{
    input.inputDate::after {    
        font-family: "Font Awesome\ 5 Free";
        content: "\F133";
        position: absolute;
        top: 4px;
        right: 5px;
        color: black;
        
    } 
}

@media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 100%;
    }
    
    .navbar-brand img {
        width: 480px;
    } 
}


@media (min-width: 1200px) and (max-width: 4080px)
{
    .container,
    .container-md,
    .container-sm {
        max-width: 1140px;
    }


}


