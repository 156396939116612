.bg-gray {
    background-color: #e2e2e2;
}
.bg-lightgray {
    background-color: #f5f5f5;
}

.bg-blue, .bg-lightblue2  {
    background-color: #009fd9;
}

.bg-lightblue {
    background-color: #35bbeb;
}

.border-lightblue {
    border-color: #35bbeb !important; 
}


.bg-darkblue {
    background-color: #0076be !important;
}



.bg-orange {
    background-color: #ee9b35;
}


.bg-yellow {
    background-color: #fcbf1b;
}

.bg-lightorange {
    background-color: #ffe9d1;
}

.bg-green {
    background-color: #4ebd14;
}

.bg-lightgreen {
    background-color: #d7f4c5;
}

.bg-lightyellow {
    background-color: #fff5a8;
}

.bg-yellow {
    background-color: #fbff00;
}

.bg-lightred {
    background-color: rgb(255, 207, 196);
}

.bg-red {
    background-color: #8c1a00;
}

.btn-pink {
    background-color: #FFC0CB;
}


/* button gradients */
.btn-yellow {
    border: 1px solid #fcbf1b;
    background-image: linear-gradient(#fbdd64, #fcbf1b);
}


.btn-blue-gradient {
    border: 1px solid #339dc5 !important;
    background-image: linear-gradient(#03b1f2, #0293c9);
    border-radius: 22px !important;
    padding-left: 22px !important;
    padding-right: 22px !important;    
}


.btn-orange-gradient {
    border: 1px solid #fcbf1a !important;
    background-image: linear-gradient(#fbdd64, #fcbf1a);
    border-radius: 22px !important;
    padding-left: 22px !important;
    padding-right: 22px !important;   
}

.btn-green-gradient {
    border: 1px solid #399957 !important;
    background-image: linear-gradient(#27c85c, #2e934d);
    border-radius: 22px !important;
    padding-left: 22px !important;
    padding-right: 22px !important;   
}



.btn-pink-gradient {  
    border: 1px solid #ffacba !important;
    background-image: linear-gradient(#FFC0CB, #d9abb1);
    border-radius: 22px !important;
    padding-left: 22px !important;
    padding-right: 22px !important;     
}



/* Background */

.bg-brown {
   background-color: #8B4513
}

.bg-lightBrown {
    background-color: #F5DEB3
}


.bg-purple {
   background-color: #800080
}

.bg-lightPurple {
    background-color: #efe6ff
}

/* text */

.text-orange {
    color: #ee9b35
}
